import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import Layout from '../../components/layout';
import Meta from '../../components/meta';
import { Face } from '../../components/face';
import { Input } from '../../components/form/input';
import { Button, Header, Subjects } from '../../components';
import { Text } from '../../components/typography';
import { post } from '../../services/api';
import useLocalStorage from '../../hooks/useLocalstorage';
import Search from '../../components/form/search/search';
import schools from '../../mocks/schools';

const Page = () => {
	const [, setStkCode] = useLocalStorage('FKN_SKT', '');
	const [, setTheme] = useLocalStorage('FKN_Theme', '');
	const [, setRole] = useLocalStorage('FKN_Role', '');
	const [submitError, setSubmitError] = useState('');
	const [isAngry, setIsAngry] = useState(false);
	const [faceReaction, setFaceReaction] = useState(0);

	const handleSubmit = async (event) => {
		setFaceReaction(prev => prev + 1);
		if (event.skole && event.klasse && event.tema) {
			setSubmitError('');
			await post('klasse', event).then(res => {
				if (!res) {
					setSubmitError('Øv, noget gik galt 😥 Prøv igen!');
				} else {
					setStkCode(res.skt_kode);
					setTheme(event.tema);
					setRole('host');
					navigate('/laerer/klasse');
				}
			});
		} else if(!event.skole) {
			setSubmitError('Du mangler at vælge en skole. 😯');
		} else if(!event.klasse) {
			setSubmitError('Du mangler at indtaste klasse. 😣');
		} else if(!event.tema) {
			setSubmitError('Du mangler at vælge et tema. 😶');
		}
	};

	return (
		<Layout>
			<Meta title="Tema vælger" description="Opsætning af siden til undervisning" />
			<Header color="white" hasBack={false} />
			<div className="min-h-screen bg-primary">
				<div className="flex items-center w-full h-screen mx-auto max-w-grid">
					<Formik
						onSubmit={handleSubmit}
						initialValues={{
							skole: '',
							klasse: '',
							tema: '',
						}}
					>
						{() => (
							<Form className="w-full">
								<div className="flex justify-between">
									<div className="inline-flex flex-col w-66p">
										<Search list={schools.map(school => school.INST_NAVN)} name="skole" className="mb-48 w-300" />
										<Input onFocus={() => setFaceReaction(prev => prev + 1)} placeholder="Klasse" name='klasse' autoComplete="off" className="mb-48 w-128" type="text" />
										<Text type="body2" className="mt-40 mb-24 italic">Vælg tema her</Text>
										<Subjects />
									</div>
									<div className="inline-block w-33p">
										<Face isAngry={isAngry} faceReaction={faceReaction} />
									</div>
								</div>
								<div className="flex items-center justify-between w-full mt-64">
									<div className="text-white-100 body2">{/*Indsæt link til temaerne her*/}</div>
									<div>
										<Link to="/" className="ml-auto mr-16 font-playfair text-15">Tilbage</Link>
										<Button variant="submitButton" type="submit" colors="bw">Vælg</Button>
									</div>
								</div>
								<p
									onMouseEnter={() => submitError?.length > 0 ? setIsAngry(true) : null}
									onMouseLeave={() => setIsAngry(false)}
									className="mt-24 font-sans font-bold text-right min-h-24">
									{submitError}
								</p>
							</Form>
						)}
					</Formik>
				</div>
			</div>
			{/* <NewsTicker /> */}
		</Layout>
	);
};

export default Page;
