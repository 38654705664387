import React from 'react';
import clsx from 'clsx';

export const SearchContainer = (props) => (
	<div
		className={clsx(
			['search', 'relative w-300', props.className],
		)}
	>
		{props.children}
	</div>
);
