import React, { useEffect, useState, useRef } from 'react';
import { Field, useField } from 'formik';
import clsx from 'clsx';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import {
	ResultItem,
	ResultsContainer,
	SearchContainer,
} from './';

const SearchBox = ({ list = [], name, className, ...rest }) => {
	const [isOpen, setOpen] = useState(false);
	const resultsRef = useRef(null);
	const [field, , helpers] = useField(name);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState(list);

	const handleSelect = listItem => {
		helpers.setValue(listItem);
		setSearchTerm(listItem);
		closeResults();
	};

	const handleChange = e => {
		helpers.setValue(e.target.value);
		setSearchTerm(e.target.value);
	};

	useEffect(() => {
		const results = list.filter(listItem => listItem.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
		setSearchResults(results);
	}, [ searchTerm ]);

	const closeResults = () => setOpen(false);

	if(!list.length) return;

	useOnClickOutside(resultsRef, closeResults);

	return (
		<SearchContainer>
			<div className={clsx(className, 'relative search__input')}>
				<Field
					value={field.value}
					name={name}
					autoComplete="off"
					className="italic search__input-field bg-primary text-17"
					placeholder="Skriv navnet på din skole her"
					onFocus={() => setOpen(true)}
					onChange={handleChange}
					{...rest}
				/>
			</div>
			{!!(isOpen && list.length) &&
				<ResultsContainer>
					<div ref={resultsRef} className="overflow-y-scroll max-h-220">
						{searchResults.map((el, idx) => (
							<ResultItem key={`result-${idx}`} onClick={() => handleSelect(el)}>
								{el}
							</ResultItem>
						),
						)}
					</div>
				</ResultsContainer>
			}
		</SearchContainer>
	);
};

export default SearchBox;
